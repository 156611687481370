import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useUserActivityEvent } from '../mutations/use-user-activity-event';
interface UseTrackLoginProps {
    enableTokenTracking?: boolean;
}

export function useTrackLogin({ enableTokenTracking }: UseTrackLoginProps = { enableTokenTracking: false }) {
    const { instance } = useMsal();
    const userTracking = useUserActivityEvent();

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const loginPayload = event.payload as AuthenticationResult;
                    const loginAccount = loginPayload.account;
                    userTracking.mutateAsync({
                        userId: loginAccount?.idTokenClaims?.sub,
                        action: 'Login',
                        eventType: 'ButtonClick',
                        userName: loginAccount?.name,
                        userEmail: loginAccount?.idTokenClaims?.email as string,
                        scopes: loginPayload.scopes,
                    });
                    break;
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    const tokenPayload = event.payload as AuthenticationResult;
                    const tokenAccount = tokenPayload.account;
                    if (!tokenPayload.fromCache && Boolean(enableTokenTracking)) {
                        userTracking.mutateAsync({
                            userId: tokenAccount?.idTokenClaims?.sub,
                            action: 'Token',
                            eventType: 'ButtonClick',
                            userName: tokenAccount?.name,
                            userEmail: tokenAccount?.idTokenClaims?.email as string,
                            scopes: tokenPayload.scopes,
                        });
                    }
                    break;
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, enableTokenTracking, userTracking]);
    return null;
}
